<template>
  <div>
    <div class="backgroundOV"></div>
    <v-card outlined>
      <v-card-title class="titleLarge">Zahlverständnis</v-card-title>
    </v-card>
    <br />
    <br />
    <v-card outlined>
      <v-card-title class="titleSmaller">Was erwartet Sie im Inhaltsbereich Zahlverständnis?</v-card-title>
      <v-card-text>Dem Zahlverständnis liegen drei wesentliche Aspekte zugrunde. Diese
        gelten weithin als Grundlage zur Ausbildung eines umfassenden
        Zahlverständnisses. Daher wird im Hintergrundwissen auf diese drei
        Bereiche eingegangen:
        <ol>
          <li>Grundvorstellungen von Zahlen</li>
          <li>Darstellungen vernetzen</li>
          <li>Zusammenhänge zwischen Zahlen</li>
        </ol>
          Im Anschluss werden an einigen Schüler:innenbeispielen Diagnose- und
          Förderungsaufgaben gestellt, sodass Sie die Facetten des
          Hintergrundwissens exemplarisch anwenden können.
          <br/>
          Den Abschluss bildet eine Kompetenzübersicht, sodass noch einmal
          transparent wird, was im Inhaltsbereich Zahlverständnis zentral ist.
      </v-card-text>
    </v-card>

    <br />

    <br />

    <v-card outlined>
      <v-card-title>Weitere Ressourcen
      </v-card-title>
      <v-card-text>
        Wenn Sie sich weiterführend mit dem Thema Zahlverständnis auseinandersetzen möchten, können Sie auch folgende
        Angebote der Plattform KIRA nutzen.
        <br />
        <ul style="list-style-type: none; padding-left: 10px">
          <li style=" margin: 8px 0px;"><a href="https://kira.dzlm.de/node/615" target="_blank"><v-icon
                left>mdi-open-in-new</v-icon>KIRA: Zahlverständnis</a></li>
          <li style=" margin: 0px 0px;"><a href="https://kira.dzlm.de/node/794" target="_blank"><v-icon
                left>mdi-open-in-new</v-icon>KIRA: Zahlen</a></li>
        </ul>
      </v-card-text>
    </v-card>

    <v-row no-gutters justify="center" align="center">
      <p class="white--text" style="z-index: 1; padding-top: 15px">
        Im unteren Bereich finden Sie die Navigation.
      </p>
    </v-row>
    <v-row no-gutters justify="center" align="center">
      <v-icon x-large class="white--text">mdi-arrow-down</v-icon>
    </v-row>
    <AppBottomNavZV next="/zahlverstaendnis/hintergrundwissen" />
  </div>
</template>

<script>
import AppBottomNavZV from "@/common/AppBottomNavZV";
export default {
  components: {
    AppBottomNavZV,
  },
};
</script>

<style scoped>
#app .titleLarge {
  font-weight: normal;
  font-size: xx-large;
}

#app .titleSmaller {
  font-weight: normal;
  font-size: x-large;
}
</style>
